import {
  type ParsedNumber,
  formatNumber as formatPhoneNumberJS,
  parseNumber,
} from 'libphonenumber-js';
import { ReactNode } from 'react';

export const getInitials = (str: string, numInitials: number) => {
  if (!str || numInitials <= 0) return '';

  const processedStr = str.replace(/^(The|An|A)\s+/i, '').trim();
  if (!processedStr) return '';

  const strArray = processedStr.split(/\s+/);

  const initialsArray = strArray
    .filter((word) => word.length > 0)
    // @ts-ignore
    .map((word) => word[0].toUpperCase())
    .slice(0, numInitials);

  return initialsArray.join('');
};

const isParsedNumber = (value: ParsedNumber | unknown): value is ParsedNumber => {
  return (value as ParsedNumber).country !== undefined;
};

export const formatPhoneNumber = (value: string) => {
  const parsedNumber = parseNumber(value);
  if (!isParsedNumber(value)) return '';
  const _parsedNumber = parsedNumber as ParsedNumber;
  if (_parsedNumber.country === 'US' || _parsedNumber.country === 'CA') {
    return formatPhoneNumberJS(_parsedNumber, 'NATIONAL');
  } else return formatPhoneNumberJS(_parsedNumber, 'INTERNATIONAL');
};

export const formatNumber = (
  value: number | string | null | undefined,
  type: 'default' | 'currency' | 'percent' | 'whole' = 'default',
  options: Intl.NumberFormatOptions = {}
) => {
  if (value === null || value === undefined) return '';
  if (typeof value === 'string') value = parseFloat(value);
  if (isNaN(value)) return '';
  switch (type) {
    case 'currency':
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        ...options,
      }).format(value);
    case 'percent':
      return new Intl.NumberFormat('en-US', {
        style: 'percent',
        minimumFractionDigits: 0,
        maximumFractionDigits: 4,
        ...options,
      }).format(value / 100);
    case 'whole':
      return new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
        ...options,
      }).format(value);
    default:
      return new Intl.NumberFormat('en-US', { ...options }).format(value);
  }
};

export const initializeInputPrice = (value: string | number | null | undefined) => {
  return formatNumber(value, 'default', {
    useGrouping: false,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const initializeInputWholeNumber = (value: string | number | null | undefined) => {
  return formatNumber(value, 'default', {
    useGrouping: false,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

export const formatCurrencyString = (value: string, allowNegative = false) => {
  let newValue = value;
  newValue = newValue.trim();
  if (allowNegative && !/^-?\$?-?(([0-9 ](\d*|\d{0,2}(,\d{3})*))|0)?(\.\d{0,2})?$/.test(newValue))
    return null;
  if (!allowNegative && !/^\$?(([0-9 ](\d*|\d{0,2}(,\d{3})*))|0)?(\.\d{0,2})?$/.test(newValue))
    return null;
  if (!/^\d*(\.)?\d{0,2}$/.test(newValue)) newValue = newValue.replace(/[^0-9.-]+/g, '');
  return newValue;
};

export const formatNumberString = (value: string, allowNegative = false) => {
  let newValue = value;
  newValue = newValue.trim();
  if (allowNegative && !/^-?[0-9]*(\.)?[0-9]*$/.test(newValue)) return null;
  if (!allowNegative && !/^[0-9]*(\.)?[0-9]*$/.test(newValue)) return null;
  return newValue;
};

export const formatWholeNumberString = (value: string) => {
  let newValue = value;
  newValue = newValue.trim();
  if (!/^[0-9]*$/.test(newValue)) return null;
  return newValue;
};

export const getHighlightedText = (value: string, highlight: string): ReactNode => {
  if (!value) return '';
  const parts = value.split(new RegExp(`(${highlight})`, 'gi'));
  return (
    <span>
      {parts.map((part, i) => (
        <span
          key={i}
          style={part.toLowerCase() === highlight.toLowerCase() ? { fontWeight: '700' } : {}}
        >
          {part}
        </span>
      ))}
    </span>
  );
};

export const getAddressString = ({
  address1,
  address2,
  city,
  state,
  zip,
  country,
}: {
  address1: string;
  address2?: string;
  city: string;
  state: string | null;
  zip: string;
  country: string;
}) => {
  if (!address1 && !city && !state && !zip && !country) return '-';
  return `${address1 ?? ''}${address2 ? `, ${address2}` : ''}${city ? `, ${city}` : ''}${
    state ? `, ${state}` : ''
  } ${zip ?? ''}${country ? ` ${country}` : ''}`;
};

export const titleCase = (str: string) => {
  if (!str) return str;

  return str
    .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase()) // Initial char (after -/_)
    .replace(/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase()); // First char after each -/_
};
